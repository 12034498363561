module.exports = {
    "development": {
        // "localhost:8080": {
        //     url: "http://dev.api.qarts.partheas.net"
        "dev.kiosk.partheas.dev.qarts.eu": {
            url: "https://api.partheas.dev.qarts.eu"
        }
    },
    "production": {
        "kiosk.mediport.partheas.net": {
            url: "https://api.mediport.partheas.net"
        },
        "kiosk.cdg.partheas.net": {
            url: "https://api.cdg.partheas.net"
        },
        "kiosk.medicis.partheas.net": {
            url: "https://api.medicis.partheas.net"
        },
        "kiosk.ocs.partheas.net": {
            url: "https://api.ocs.partheas.net"
        },
        "kiosk.partheas.partheas.net": {
            url: "https://api.partheas.partheas.net"
        },
        "kiosk.beukenlaan.partheas.net": {
            url: "https://api.beukenlaan.partheas.net"
        },
        "kiosk.devaart.partheas.net": {
            url: "https://api.devaart.partheas.net"
        },
        "PRODUCTION.TENANT_H": {
            url: "PRODUCTION.TENANT_H_API_REDIRECT_URL"
        },
        "PRODUCTION.TENANT_I": {
            url: "PRODUCTION.TENANT_I_API_REDIRECT_URL"
        },
        "PRODUCTION.TENANT_J": {
            url: "PRODUCTION.TENANT_J_API_REDIRECT_URL"
        },
        "PRODUCTION.TENANT_K": {
            url: "PRODUCTION.TENANT_K_API_REDIRECT_URL"
        },
        "PRODUCTION.TENANT_L": {
            url: "PRODUCTION.TENANT_L_API_REDIRECT_URL"
        },
        "PRODUCTION.TENANT_M": {
            url: "PRODUCTION.TENANT_M_API_REDIRECT_URL"
        },
        "PRODUCTION.TENANT_N": {
            url: "PRODUCTION.TENANT_N_API_REDIRECT_URL"
        },
        "PRODUCTION.TENANT_O": {
            url: "PRODUCTION.TENANT_O_API_REDIRECT_URL"
        },
        "PRODUCTION.TENANT_P": {
            url: "PRODUCTION.TENANT_P_API_REDIRECT_URL"
        },
        "PRODUCTION.TENANT_Q": {
            url: "PRODUCTION.TENANT_Q_API_REDIRECT_URL"
        },
        "PRODUCTION.TENANT_R": {
            url: "PRODUCTION.TENANT_R_API_REDIRECT_URL"
        },
        "PRODUCTION.TENANT_S": {
            url: "PRODUCTION.TENANT_S_API_REDIRECT_URL"
        },
        "PRODUCTION.TENANT_T": {
            url: "PRODUCTION.TENANT_T_API_REDIRECT_URL"
        },
        "PRODUCTION.TENANT_U": {
            url: "PRODUCTION.TENANT_U_API_REDIRECT_URL"
        },
        "PRODUCTION.TENANT_V": {
            url: "PRODUCTION.TENANT_V_API_REDIRECT_URL"
        },
        "PRODUCTION.TENANT_W": {
            url: "PRODUCTION.TENANT_W_API_REDIRECT_URL"
        },
        "PRODUCTION.TENANT_X": {
            url: "PRODUCTION.TENANT_X_API_REDIRECT_URL"
        },
        "PRODUCTION.TENANT_Y": {
            url: "PRODUCTION.TENANT_Y_API_REDIRECT_URL"
        },
        "PRODUCTION.TENANT_Z": {
            url: "PRODUCTION.TENANT_Z_API_REDIRECT_URL"
        }
    }
}